






























import {Vue, Component, Prop} from "vue-property-decorator";
import SearchFriendItemType from "./indexType";

@Component({name: "SearchFriendItem"})
export default class SearchFriendItem extends Vue implements SearchFriendItemType{

    handleToPath(){
        this.$router.push({
            name:"addFriend",
            query:{
                id:this.getState == 'recommend' ? this.getData && this.getData.friendId || '0' : this.getData && this.getData.friendId || '0',
                state:""
            }
        })
    }

    get getPhoto(){
        return this.getData && this.getData.headImg || this.getData.friendHeadImage || require("@/assets/icon/Basics/default.png")
    }

    get getSex(){
        if ( this.getData && this.getData.sex && this.getData.sex == 1 ){
            return require("@/assets/icon/Basics/sex.png")
        }else{
            return require("@/assets/icon/Basics/sex1.png")
        }
    }

    @Prop(Object)
    data!:any
    get getData(){ return this.data }

    @Prop(Number)
    index!:number
    get getIndex(){ return this.index }

    @Prop(String)
    state!:string
    get getState(){ return this.state || '' }
}
