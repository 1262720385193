






















import {Vue, Component, Watch} from "vue-property-decorator";
import SchoolCampusType from "./SchoolCampus";
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import PullDownUpList from "@/components/ShareComponent/PullDownUp.vue"
import ListBottom from "@/components/ShareComponent/ListBottom.vue";
import ZoomPage from "../../../impView/PageSafety";
import { getSchoolFriends } from './Server';
import {SchoolCampusStore} from "@/views/Friend/SchoolCampus/Store";
import SearchFriendItem from "@/views/Friend/components/FriendItem/index.vue";

@Component({name: "SchoolCampus",components:{ HeadTop,PullDownUpList,ListBottom,SearchFriendItem }})
export default class SchoolCampus extends ZoomPage implements SchoolCampusType{
    PullDown = false
    UpDownBool = false
    StopUp = false
    List:any[] = []

    mounted(){
        this.loadingShow = false
        this.List = this.getList
        if ( this.List.length < 1 ) this.handleUpData(true);
    }

    handlePullDown(){
        let upData = SchoolCampusStore.getSchoolCampusUpData;
        upData.pageNo = 1;
        SchoolCampusStore.SetSchoolCampusUpData(upData)
        this.StopUp = false
        this.PullDown = true
        this.handleUpData(true)
    }

    handleUpDown(){
        if ( this.StopUp )return;
        let upData = SchoolCampusStore.getSchoolCampusUpData;
        upData.pageNo += 1;
        SchoolCampusStore.SetSchoolCampusUpData(upData)
        this.UpDownBool = true
        this.handleUpData()
    }

    handleUpData(bool=false){
        getSchoolFriends().then(res=>{
            this.PullDown = false
            this.UpDownBool = false
            if ( res.length < SchoolCampusStore.getSchoolCampusUpData.pageSize )this.StopUp = true;
            if ( bool )
                SchoolCampusStore.SetSchoolCampusList( res );
            else
                SchoolCampusStore.SetSchoolCampusList( SchoolCampusStore.getSchoolCampusList.concat(res) );
        })
    }

    get getList(){
        return SchoolCampusStore.getSchoolCampusList
    }
    @Watch("getList")
    changeList(newVal:any[]){
        this.List = newVal
    }
}
